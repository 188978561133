* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Pretendard', 'sans-serif';
}

html,
body {
  min-width: fit-content;
  height: 100%;
  background: radial-gradient(60.37% 60.37% at 50% 1.11%, #e9e9ff 0%, #ffffff 100%);
}

@font-face {
  font-family: 'Pretendard';
  src: local('PretendardVariable'), url(./fonts/PretendardVariable.ttf) format('truetype');
}
